
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { sleep } from '@/utils/helpers';

@Component({})
export default class MessageToaster extends mixins() {
@Prop({ type: String, default: '' }) message!: string;

@Prop({ type: Number, default: 3000 }) waitTime!: number;

showSavingDetailsBannerID: any = false;

savingDetailsBannerIDText: any = false;

savingInputValueChange: any = false;

setSavingDetailsBannerID(text) {
  this.savingInputValueChange = true;
  this.showSavingDetailsBannerID = true;
  this.setSavingDetailsBannerIDText(text);
}

setSavingDetailsBannerIDText(text) {
  this.savingDetailsBannerIDText = text;
}

async removeSavingDetailsBannerID(wait = 5000, text = '') {
  this.savingInputValueChange = false;
  if (text !== '') {
    this.setSavingDetailsBannerIDText(text);
  }
  await sleep(wait);
  this.showSavingDetailsBannerID = false;
  this.savingDetailsBannerIDText = '';
  this.$emit('close-popup')
}

async created() {
  this.setSavingDetailsBannerID(this.message);
  await this.removeSavingDetailsBannerID(this.waitTime);
}
}

